/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48I2ZmNTEwMCIsIj9lcjwjZmZjYmIzIiwiO2VyPCNmZjM3MDB$LCIlPmBePCMwMGJjYTkiLCI~ZXI8I2IzZWJlNSIsIjtlcjwjMDBhNThmfiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00IiwiZmFtaWx5PExhdG9$LGBAPCgtMyIsImZhbWlseTxMYXRvfixgQDwoLTIiLCJmYW1pbHk8TGF0b34sYEA8KC0xIiwiZmFtaWx5PExhdG9$LGBAPGhlYWRsaW5lIiwiZmFtaWx5PExhdG9$LGBAPHRpdGxlIiwiZmFtaWx5PExhdG9$LGBAPHN1YiktMiIsImZhbWlseTxMYXRvfixgQDxzdWIpLTEiLCJmYW1pbHk8TGF0b34sYEA8Ym9keS0yIiwiZmFtaWx5PExhdG9$LGBAPGJvZHktMSIsImZhbWlseTxMYXRvfixgQDxidXR0b24iLCJmYW1pbHk8TGF0b34sYEA8Y2FwdGlvbiIsImZhbWlseTxMYXRvfixgQDxpbnB1dCIsImZhbWlseTxMYXRvIiwic2l6ZT5udWxsfV0sImljb25zPFNoYXJwIiwiP25lc3M$ZmFsc2UsInZlcnNpb24$MTF9
*/
@use '@angular/material' as mat;

@import 'src/assets/scss/variables.scss';


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Sharp');

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'sans-serif', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'sans-serif', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'sans-serif', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'sans-serif', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'sans-serif', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'sans-serif', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'sans-serif', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'sans-serif', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'sans-serif', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'sans-serif', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'sans-serif', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'sans-serif', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'sans-serif', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba(rgba(black, 0.87), 0.54);
$dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
$dark-dividers: rgba(rgba(black, 0.87), 0.12);
$dark-focused: rgba(rgba(black, 0.87), 0.12);

mat.$light-theme-foreground-palette: (
  base:              black,
  divider:           rgba(black, 0.12),
  dividers:          rgba(black, 0.12),
  disabled:          rgba(black, 0.38),
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     rgba(black, 0.38),
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         rgba(black, 0.38),
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              rgba(black, 0.87),
  slider-min:        rgba(black, 0.87),
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

mat.$dark-theme-foreground-palette: (
  base:              $light-text,
  divider:           rgba(white, 0.12),
  dividers:          rgba(white, 0.12),
  disabled:          rgba(white, 0.5),
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     rgba(white, 0.5),
  elevation:         black,
  hint-text:         rgba(white, 0.5),
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

mat.$light-theme-background-palette: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           rgba(black, 0.12),
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
mat.$dark-theme-background-palette: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           rgba(white, 0.12),
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);

// Compute font config
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($fontConfig);`
@include mat.all-legacy-component-typographies($fontConfig);
@include mat.legacy-core();

// Theme Config

body {
  --primary-color: #ff5100;
  --primary-lighter-color: #ffcbb3;
  --primary-darker-color: #db2f00;
  --text-primary-color: #{white};
  --text-primary-lighter-color: #{rgba(black, 0.87)};
  --text-primary-darker-color: #{white};
  --accent-color: #00bca9;
  --accent-lighter-color: #b3ebe5;
  --accent-darker-color: #00a58f;
  --text-accent-color: #{rgba(black, 0.87)};
  --text-accent-lighter-color: #{rgba(black, 0.87)};
  --text-accent-darker-color: #{white};
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{white};
  --text-warn-lighter-color: #{rgba(black, 0.87)};
  --text-warn-darker-color: #{white};
} 
$mat-primary: (
  main: #ff5100,
  lighter: #ffcbb3,
  darker: #db2f00,
  200: #ff5100, // For slide toggle,
  contrast : (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: #00bca9,
  lighter: #b3ebe5,
  darker: #00a58f,
  200: #00bca9, // For slide toggle,
  contrast : (
    main: $light-accent-text,
    lighter: $dark-accent-text,
    darker: $light-accent-text,
  )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000, // For slide toggle,
  contrast : (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: mat.define-dark-theme($theme-primary, $theme-accent, $theme-warn);
$altTheme: mat.define-light-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include mat.all-legacy-component-themes($theme);

.theme-alternate {
  @include mat.all-legacy-component-themes($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1em!important;
  border-radius: 1em!important;
  font-size: 14px!important;
  color: $white!important;
  align-items: center!important;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Sharp', 'Material Icons', sans-serif;

  .mat-badge-content {
    font-family: sans-serif;
  }
}


input.mat-input-element {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  color: $light-text;
}

mat-icon {
  line-height: 1;
}

mat-dialog-content {
  background-color: rgba(0,0,0,0.1);
}